<template>
  <div class="min-h-screen flex flex-col items-center justify-center bg-gray-50">
    <h1 class="text-2xl font-bold text-gray-800 mb-4">LINEユーザー連携</h1>
    <div v-if="loading" class="text-gray-500">LINEユーザーIDを設定しています...</div>
    <div v-else>
      <p v-if="successMessage" class="text-green-600">{{ successMessage }}</p>
      <p v-if="errorMessage" class="text-red-500">{{ errorMessage }}</p>
      <router-link to="/" class="text-blue-500 underline">ホームへ戻る</router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'LINEComponented',
  data() {
    return {
      loading: true,
      successMessage: '',
      errorMessage: ''
    };
  },
  computed: {
    ...mapGetters({
      uid: 'auth/userId',
      isLoggedIn: 'auth/isLoggedIn', // ログイン状態を確認
    })
  },

  async created() {
    try {
      // URLパラメーターから認可コードを取得
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');

      if (!code) {
        throw new Error('LINEの認証コードが見つかりませんでした。');
      }

      const hasScheduleId = this.$route.query.hasScheduleId;
      // 認証コードをバックエンドに送信してアクセストークンとユーザー情報を取得
      const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/line-token`, {
        code,
        state,
        redirectUri:`http://portal.tsuku.io/line?hasScheduleId=${hasScheduleId}`, // コールバックURL
      });

      const { lineUserId } = response.data;

      
      // ユーザー情報の更新リクエスト
      await axios.post(`${process.env.VUE_APP_BASE_URL}/updateuser`, {
        uid: this.uid,
        line_user_id: lineUserId,
      });

      this.successMessage = 'LINEユーザーIDが正常に設定されました！';
      if (hasScheduleId) {
        window.location.href = 'https://www.tsuku.io/school/trial-complete';
      } else {
        this.$router.push('/');
      }
      
    } catch (error) {
      console.error('LINEユーザーID設定エラー:', error);
      this.errorMessage = 'LINEユーザーIDの設定に失敗しました。再度お試しください。';
    } finally {
      this.loading = false;
    }
  }
};
</script>

<style scoped>
/* 必要に応じてスタイルを追加 */
</style>
