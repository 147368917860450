<template>
  <div class="bg-gray-50 min-h-screen">
    <header-component></header-component>
    <div class="reservation-page p-6 bg-gray-50">
      <h1 class="text-3xl font-bold text-orange-500 mb-6">予約</h1>
      <div class="space-y-4">
        <div class="space-y-2">
          <label class="block text-sm font-medium text-gray-700">教室を選ぶ:</label>
          <select v-model="selectedClassroom" class="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-orange-500 focus:border-orange-500" required>
            <option value="">すべての教室</option>
            <option v-for="classroom in classrooms" :key="classroom" :value="classroom">
              {{ classroom }}
            </option>
          </select>
        </div>
        <div v-if="filteredClasses.length > 0">
          <h2 class="text-2xl font-bold text-gray-700 mb-4">{{ selectedClassroom || 'すべての教室' }}</h2>
          <ul class="space-y-4">
            <li v-for="classItem in filteredClasses" :key="classItem.id" class="p-4 border rounded-md shadow-sm bg-white">
              <div class="flex justify-between items-center">
                <div>
                  <p class="text-lg font-semibold">{{ classItem.class }} - {{ classItem.startAtFormatted }}</p>
                  <p class="text-sm text-gray-500">{{ classItem.place }}</p>
                </div>
                <button
                  @click="makeReservation(classItem.id)"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                >
                  予約する
                </button>
              </div>
            </li>
          </ul>
        </div>
        <div v-else>
          <p class="text-gray-500">{{ selectedClassroom || 'すべての教室' }}は、予約できる授業がありません。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import HeaderComponent from '@/components/Header.vue';

export default {
  name: 'Reservation',
  components: {
    'header-component': HeaderComponent,
  },
  data() {
    return {
      classrooms: [],
      selectedClassroom: '',
      classes: [],
    };
  },
  computed: {
    ...mapGetters({
      userId: 'auth/userId',
    }),
    futureClasses() {
      return this.classes.filter(classItem => new Date(classItem.startAt) > new Date());
    },
    filteredClasses() {
      if (this.selectedClassroom === '') {
        return this.futureClasses;
      }
      return this.futureClasses.filter(classItem => classItem.place === this.selectedClassroom);
    },
  },
  async created() {
    try {
      const response = await axios.get(process.env.VUE_APP_BASE_URL + '/prospectiveDate', {
        params: {
          uid: this.userId,
        },
      });

      this.classes = response.data.map(classItem => ({
        ...classItem,
        startAtFormatted: new Date(classItem.startAt).toLocaleString('ja-JP'),
      }));

      this.classrooms = [...new Set(this.futureClasses.map(classItem => classItem.place))];
    } catch (error) {
      console.error('Error fetching classes:', error);
      // alert('tsuku.io Schoolへの通塾記録が確認できませんでした。');
    }
  },
  methods: {
    async makeReservation(scheduleId) {
      try {
        const result = await axios.post(process.env.VUE_APP_BASE_URL + '/doreservation', {
          studentUID: this.userId,
          scheduleId: scheduleId,
        });
        if (result.data.status === 'success') {
          alert('予約が完了しました！');
          this.classes = this.classes.filter(classItem => classItem.id !== scheduleId);
        } else {
          alert('この授業は満席です');
        }
      } catch (error) {
        alert('予期せぬ理由で予約に失敗しました。');
      }
    },
  },
};
</script>

<style scoped>
/* 必要に応じてスタイルを追加 */
</style>
